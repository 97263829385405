








































































































import { defineComponent, ref, inject, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'PreferencePreview',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    getData: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);

    const showDialog = computed({
      get: () => props.isOpen,
      set: newVal => {
        ctx.emit('toggle');
      }
    });

    return { showDialog, readonly };
  }
});
